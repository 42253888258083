import i18next from "i18next";
import moment from "moment";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useField, ErrorMessage } from "formik";
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardText,
  CardTitle,
  List,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import clearSearch from "../../assets/img/black-cross.svg";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import dropDownArrow from "../../assets/img/dropdown-arrow.svg";
import NoDataFoundIcon from "../../assets/img/no_search_found_icon.svg";
import searchIcon from "../../assets/img/search-icon.svg";
import ChipCrossIcon from "../../assets/img/chip_cross.svg";

import {
  activateDeactivateSubscriptionAPI,
  createSubscriptionAPI,
  deleteJobAPI,
  deleteSubscriptionAPI,
  editSubscriptionAPI,
  getJobListAPI,
  getSubscriptionAPI,
} from "../../Utils/api-helper";
import { goClone, showLoader, showToast } from "../../Utils/common-helper";
import {
  saveSelectedJobData,
  saveHiringBoardParamData,
} from "../../redux/actions/hiring-board.actions";
import ConfirmAlert from "../Confirm-Alert/index";
import "./subscription-details.scss";
import { TextField, TextArea1 } from "../TextField";
import { FaEdit } from "react-icons/fa";
import TextArea from "../textArea/TextArea";

import { Modal, ModalBody } from "reactstrap";
import ModalCrossBlack from "../../assets/img/black-cross.svg";
import AppForm from "../AppForm";
import { addSubscriptionValidation } from "../../Utils/ValidationService";

import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

let editData = [];

const exceptThisSymbols = ["e", "E", "-", "+"];
const exceptThisSymbols2 = ["e", "E", "-", "+", "."];

let jobEmploymentStatusOptions = [
  { id: "ALL", value: "All" },
  { id: "FULL_TIME", value: "Full-Time" },
  { id: "CONTRACT", value: "Contract" },
];
let crewList = [
  { id: "ALL", value: "All" },
  { id: "CAPTAIN", value: "Captain" },
  { id: "FIRST_OFFICER", value: "First Officer" },
  { id: "FLIGHT_ATTENDANT", value: "Flight Attendant" },
  { id: "CABIN_ATTENDANT", value: "Cabin Attendant" },
];

function SubscriptionDetails(props) {
  // const [ meta] = useField();

  let { initialValues, validationSchema } = addSubscriptionValidation();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jobList, setJobList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [deleteJob, showDelete] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState("");
  const [deleteJobIndex, setDeleteJobIndex] = useState("");
  const [modal, setModal] = useState(false);
  const [editSubscriptionModal, setEditSubscriptionModal] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();
  let [preSearchText, setPreSearchText] = useState("");
  let [params, setParams] = useState({
    page: 0,
    size: 20,
    searchText: "",
    filter: "ALL",
    role: "ALL",
    sort: "NAME",
    order: "ASC",
    selectedFilter: { id: "ALL", value: "All" },
    selectedRole: { id: "ALL", value: "All" },
  });
  let arrayHolder = goClone(jobList);
  // const [initialValues, setinitialValues] = useState('');
  // const [validationSchema, setvalidationSchema] = useState(0);
  const [plan_name, setPlanName] = useState("");
  const [monthlyPlanAmmount, setMonthlyPlanAmmount] = useState();
  const [sixMonthlyPlanAmmount, setSixMonthlyPlanAmmount] = useState();
  const [yearlyPlanAmmount, setYearlyPlanAmmount] = useState();
  const [noOfAircraft, setNoOfAirCraft] = useState();
  const [noOfPassenger, setNoOfPassenget] = useState();
  const [noOfPilot, setNoOfPilots] = useState();
  const [noOfAttendance, setNoOfAttendance] = useState();
  const [planDetails, setPlanDetails] = useState("");
  const [isPilotCheckBoxChecked, setIsPilotCheckBoxChecked] = useState(false);
  const [
    isFlightAttendentCheckBoxChecked,
    setIsFlightAttendentCheckBoxChecked,
  ] = useState(false);
  const [isGenralCheckBoxChecked, setIsGenralCheckBoxChecked] = useState(false);
  const [isApplyJobCheckBoxChecked, setIsApplyJobCheckBoxChecked] =
    useState(false);
  const [allSubscriptionList, setAllSubscriptionList] = useState([]);
  const [toggleOn, setToggleOn] = useState(false);
  const [deleteSubscriptionId, setDeletSubscriptionId] = useState("");
  const [toggleSubscriptionId, setToggleSubscriptionId] = useState();
  const [editSubscriptionid, setEditSubscriptionId] = useState("");
  const [isActivate, setIsActivate] = useState();
  const [ids, setIds] = useState([]);
  const [planNameValid, setPlanNameValid] = useState();
  const [monthlyPlanValid, setMonthlyPlanValid] = useState();
  const [sixMonthlyPlanValid, setSixMonthlyPlanValid] = useState();
  const [yearlyPlanValid, setYearlyPlanValid] = useState();
  const [noOfAircraftValid, setNoOfAircraftValid] = useState();
  const [noOfPassengerValid, setNoOfPassengerValid] = useState();
  const [noOfPilotValid, setNoOfPilotValid] = useState();
  const [noOfAttendentValid, setNoOfAttendentValid] = useState();
  const [planDetailsValid, setPlanDetailsValid] = useState();
  const [planTypeDropdownValid, setPlanTypeDropdownvalid] = useState();
  const [roleValid, setRoleValid] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [drowpdownFlightDept, setDropdownFlightDept] = useState();
  const [dropdownJobPost, setDropdownJobPost] = useState();
  const [dropdownKeyword, setDropdownKeyword] = useState("Plan Type");
  const [showDropdown, setShowDropdown] = useState(false);

  // ******************Header filter function*****************
  const handleFilterClick = (selectedOption, type) => {
    const tempParams = { ...params };
    if (type === "role") {
      tempParams.role = selectedOption?.id;
      tempParams.selectedRole = selectedOption;
    } else {
      tempParams.filter = selectedOption.id;
      tempParams.selectedFilter = selectedOption;
    }
    tempParams.page = 0;
    setParams(tempParams);
  };

  const handleClearSearch = () => {
    const tempParams = { ...params };
    tempParams.searchText = "";
    setParams(tempParams);
    let temp = { ...preSearchText };
    temp = "";
    setPreSearchText(temp);
  };

  const handleSearchChange = (text) => {
    if (preSearchText != text) {
      setPreSearchText(text);
    } else {
    }
  };

  const submitSearch = (e) => {
    if (e.charCode == 13) {
      // const newData = arrayHolder.filter(item => {
      //     const itemData = item?.jobTitle?.toUpperCase();
      //     const textData = preSearchText.toUpperCase();
      //     return itemData.indexOf(textData) > -1;
      // });
      // setJobList(newData)

      const tempParams = { ...params };
      tempParams.page = 0;
      tempParams.searchText = preSearchText;
      setParams(tempParams);
    }
  };

  const stopEventBubbling = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  // const handleDeleteJob = (e, item, index) => {
  //     stopEventBubbling(e);
  //     setDeleteJobId(item?.id);
  //     setDeleteJobIndex(index);
  //     showDelete(true);
  // };

  const handleDeleteSubscription = (e, item, index) => {
    console.log("delete==>", e.id);
    setDeletSubscriptionId(e.id);
    showDelete(true);
  };

  const handleEditSubscription = (e, item) => {
    setEditSubscriptionModal(true);
    setEditSubscriptionId(e.id);
    setIsPilotCheckBoxChecked(false);
    setIsApplyJobCheckBoxChecked(false);
    setIsFlightAttendentCheckBoxChecked(false);
    setIsGenralCheckBoxChecked(false);
    console.log("editSubscriptinon==>", e);
    setPlanName(e.planName);
    setPlanDetails(e.planDetailsText);
    setIsApplyJobCheckBoxChecked(e?.isEligibleForUnlimitedPost);
    setNoOfAirCraft(e?.numberOfAircrafts);
    setNoOfAttendance(e?.numberOfAttendants);
    setNoOfPassenget(e?.numberOfAdminAndManagers);
    setNoOfPilots(e?.numberOfPilot);
    setIsActivate(e?.isActivate);
    setDropdownKeyword(e?.planType);

    if (
      e?.role[0]?.role == "PILOT" ||
      e?.role[1]?.role == "PILOT" ||
      e?.role[2]?.role == "PILOT"
    ) {
      setIsPilotCheckBoxChecked(true);
    }
    if (
      e?.role[0]?.role == "FLIGHT_ATTENDANT" ||
      e?.role[1]?.role == "FLIGHT_ATTENDANT" ||
      e?.role[2]?.role == "FLIGHT_ATTENDANT"
    ) {
      setIsFlightAttendentCheckBoxChecked(true);
    }
    if (
      e?.role[0]?.role == "GENERAL" ||
      e?.role[1]?.role == "GENERAL" ||
      e?.role[2]?.role == "GENERAL"
    ) {
      setIsGenralCheckBoxChecked(true);
    }

    if (e?.planTypesDetails[0]?.planDuration == 30) {
      setMonthlyPlanAmmount(e.planTypesDetails[0].planAmount);
    }
    if (e?.planTypesDetails[1]?.planDuration == 180) {
      setSixMonthlyPlanAmmount(e.planTypesDetails[1].planAmount);
    }
    if (e?.planTypesDetails[2]?.planDuration == 365) {
      setYearlyPlanAmmount(e.planTypesDetails[2].planAmount);
    }
  };

  const editSubscriptionAddButtonClicked = () => {
    //edit subscription api ==========================================================
    // alert("edit")

    if (plan_name == "" || plan_name == null) {
      setPlanNameValid("Please enter plan name");
      return;
    }
    if (monthlyPlanAmmount === "") {
      setMonthlyPlanValid("Please enter the cost here");
      return;
    }

    if (
      isPilotCheckBoxChecked == false &&
      isFlightAttendentCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      // alert("Please add role")
      showToast("Please add role", "error");
      return;
    }
    if (sixMonthlyPlanAmmount === "") {
      setSixMonthlyPlanValid("Please enter the cost here");
      return;
    }
    if (yearlyPlanAmmount === "") {
      setYearlyPlanValid("Please enter the cost here");
      return;
    }
    if (dropdownKeyword == "Flight Department") {
      if (noOfAircraft === "") {
        setNoOfAircraftValid("Please enter the aircraft count");
        return;
      }

      if (noOfAircraft < 1) {
        setNoOfAircraftValid("Minimum aircraft count is 1");
        return;
      }
      if (noOfPassenger === "") {
        setNoOfPassengerValid("Please enter the General user count");
        return;
      }

      if (noOfPassenger < 1) {
        setNoOfPassengerValid("Minimum general user count is 1");
        return;
      }
      if (noOfPilot === "") {
        setNoOfPilotValid("Please enter the pilot count");
        return;
      }

      if (noOfPilot < 1) {
        setNoOfPilotValid("Minimum pilot count is 1");
        return;
      }
      if (noOfAttendance === "") {
        setNoOfAttendentValid("Please enter the Flight attendant count");
        return;
      }
      if (noOfAttendance < 1) {
        setNoOfAttendentValid("Minimum flight attendent count is 1");
        return;
      }
    }

    if (planDetails == "" || planDetails == null) {
      setPlanDetailsValid("Please enter the Plan Description");
      return;
    }

    if (
      monthlyPlanAmmount == 0 &&
      sixMonthlyPlanAmmount == 0 &&
      yearlyPlanAmmount == 0
    ) {
      showToast(
        "Please add at least one cost to create a subscription",
        "error"
      );
      return;
    }

    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      console.log("isActiavte==>", editSubscriptionid);
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }

    if (
      isFlightAttendentCheckBoxChecked == true &&
      isPilotCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      console.log("isActiavte==>", editSubscriptionid);
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "FLIGHT_ATTENDANT",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }

    if (
      isGenralCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == false &&
      isPilotCheckBoxChecked == false
    ) {
      console.log("isActiavte==>", editSubscriptionid);
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }
    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == true &&
      isGenralCheckBoxChecked == false
    ) {
      console.log(
        "isActiavte==>",
        isPilotCheckBoxChecked,
        isFlightAttendentCheckBoxChecked
      );
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "FLIGHT_ATTENDANT",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }

    if (
      isFlightAttendentCheckBoxChecked == true &&
      isGenralCheckBoxChecked == true &&
      isPilotCheckBoxChecked == false
    ) {
      console.log("isActiavte==>", editSubscriptionid);
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "FLIGHT_ATTENDANT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }

    if (
      isPilotCheckBoxChecked == true &&
      isGenralCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == false
    ) {
      console.log("isActiavte==>", editSubscriptionid);
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }

    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == true &&
      isGenralCheckBoxChecked == true
    ) {
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "FLIGHT_ATTENDANT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }
    if (
      isPilotCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false &&
      isFlightAttendentCheckBoxChecked == false
    ) {
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        id: editSubscriptionid,
        isActivate: isActivate,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "FLIGHT_ATTENDANT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("editPayload", payload);

      editSubscriptionAPI(payload)
        .then((response) => {
          console.log("edit subscription response", response);
          if (response?.code == 200) {
            alert(response.message);
            setIsLoading(false);
            editToggle();
            getAllSubscription();
            clearStateMethod();
          } else {
            if (response?.code == 400) {
              alert(response.message);
              setIsLoading(false);
            }
            // alert("khj")
          }
        })
        .catch((error) => {
          console.log("edit subscription error", error);
        });
    }
  };

  const getAllSubscription = () => {
    setIsLoading(true);
    getSubscriptionAPI()
      .then((response) => {
        console.log("@@@@@@@@", response.data);
        if (response.code == 200) {
          setAllSubscriptionList(response.data);
          setIsLoading(false);
        } else {
        }
      })
      .catch((error) => {
        console.log("erroee-------------", error);
        setIsLoading(false);
      });
  };

  // *********API CALLS**************
  useEffect(() => {
    getAllSubscription();

    if (props.paramData && props.paramData.length) {
      const tempParams = { ...params };
      tempParams.filter = props.paramData[0].filters;
      tempParams.selectedRole = props.paramData[0].selectedRole;
      tempParams.selectedFilter = props.paramData[0].selectedFilter;
      tempParams.role = props.paramData[0].role;
      tempParams.page = 0;
      props.saveHiringBoardParamData([]);
      setParams(tempParams);
    } else {
      getJobList(params.page === 0 ? false : true);
    }
  }, [params]);

  const getJobList = (onScroll) => {
    setIsLoading(true);
    let body = {
      crewPosition: params?.selectedRole?.id,
      employmentStatus: params?.selectedFilter?.id,
      page: params.page,
      size: params.size,
      searchText: params.searchText,
    };
    getJobListAPI(body)
      .then((res) => {
        setIsLoading(false);
        setIsDataFetched(true);
        if (res.data.list && res.data.list.length > 0) {
          let allJobList = [];
          if (onScroll) {
            allJobList = jobList.concat(res.data.list);
          } else {
            allJobList = [];
            allJobList = res.data.list;
          }
          setTotalPages(res?.data?.totalPages);
          if (res.data.list.length < params.size) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }

          setJobList(allJobList);
          if (params.page === 0) {
            let x = document.getElementById("containerScroll");
            x.scrollTop = 0;
          }
        } else {
          setJobList([]);
        }
      })
      .catch((error) => {
        setIsDataFetched(true);
        setIsLoading(false);
        if (error.data && error.data.message) {
          showToast(error.data.message, "error");
        }
      });
  };
  const handleDelete = () => {
    // setIsLoading(true);
    // deleteJobAPI(deleteJobId).then(deleteJob => {
    //     setIsLoading(false);
    //     showToast(deleteJob.message, "success");
    //     const tempParams = { ...params };
    //     tempParams.page = 0;
    //     setParams(tempParams);
    //     //jobList.splice(deleteJobIndex, 1);
    //     setDeleteJobId('')
    //     setDeleteJobIndex('')
    // }).catch((error) => {
    //     setIsDataFetched(true);
    //     setIsLoading(false);
    //     if (error.data && error.data.message) {
    //         showToast(error.data.message, "error");
    //     }
    // });
    setIsLoading(true);
    let payload = {
      subscriptionID: deleteSubscriptionId,
    };
    deleteSubscriptionAPI(payload)
      .then((response) => {
        console.log("delete subscription response", response);
        if (response?.code == 200) {
          setIsLoading(false);
          alert(response.message);
          getAllSubscription();
        } else {
          if (response?.code == 400) {
            setIsLoading(false);
            alert(response.message);
            getAllSubscription();
          }
        }
      })
      .catch((error) => {
        console.log("delete subscription error", error);
        setIsLoading(false);
      });
  };

  const isChecked = (itemId) => {
    const isThere = ids.includes(itemId);
    return isThere;
  };

  const handleActivateDeactivate = (item, index, e) => {
    // setToggleOn(!toggleOn)
    setIsLoading(true);
    console.log("activateDeactivate=>", item.id);
    // setToggleSubscriptionId(item.id)

    let toogleID;
    toogleID = item.id;

    // var list = [...allSubscriptionList]
    // console.log("list", list[e])
    // list[e].isActivate = !list[e].isActivate
    // setAllSubscriptionList(list)
    // console.log("set", allSubscriptionList[e].isActivate)
    console.log("toggleID", toogleID);

    let payload = {
      subscriptionID: toogleID,
    };

    console.log("activate payload", payload);

    activateDeactivateSubscriptionAPI(payload)
      .then((response) => {
        console.log("activate deactivate api response", response);
        if (response?.code == 200) {
          getAllSubscription();
          // alert(response.message)
          // setIsLoading(false)
        } else {
          console.log("nothing");
        }
      })
      .catch((error) => {
        console.log("activate deactivate api error", error);
      });
  };

  const onScrollJobList = () => {
    const tempParams = { ...params };
    tempParams.page++;
    if (totalPages > tempParams.page) {
      setParams(tempParams);
    } else {
      setHasMore(false);
    }
  };

  // ***********************NAVIGATION***********************
  const navigateToJobDetails = (item, crewRole, jobEmploymentStatus) => {
    props.saveSelectedJobData(item);
    let obj = {
      selectedFilter: params.selectedFilter,
      filters: params.filter,
      selectedRole: params.selectedRole,
      role: params.role,
    };
    let arry = [];
    arry.push(obj);
    props.saveHiringBoardParamData(arry);
    history.push({
      pathname: "/job-details",
      state: {
        state: item,
        crewRole: crewRole,
        jobEmploymentStatus: jobEmploymentStatus,
        handleDelete,
      },
    });
  };

  // ***********************MISC FUNCTIONS***********************
  const valueProviderForLists = (pos, list) => {
    let label = list.filter((i) => i.id == pos)[0];
    return label;
  };

  const renderEmptyView = () => {
    return allSubscriptionList.length === 0 && !isLoading ? (
      <div className="no-hiring-data-wrapper text-center">
        <div>
          <img src={NoDataFoundIcon} alt="no-data-found-icon" />
          <h6 className="no-data-title pt-3">{i18next.t("NO_DATA_FOUND")} </h6>
        </div>
      </div>
    ) : null;
  };

  const formatString = (str) => {
    return str
      .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
      .replace(/^[^ ]/g, (match) => match.toUpperCase());
  };

  const renderJobList = () => {
    console.log("render==>", allSubscriptionList);
    return (
      <List type="unstyled" className="job-list">
        <InfiniteScroll
          dataLength={jobList.length} //This is important field to render the next data
          next={onScrollJobList}
          hasMore={hasMore}
          // loader={<h4>Loading...</h4>}
          scrollableTarget="containerScroll"
        >
          {allSubscriptionList?.length && isDataFetched
            ? allSubscriptionList.map((item, index) => {
                // let crewRole = valueProviderForLists(item.crewPosition, crewList).value;
                // let jobEmploymentStatus = valueProviderForLists(item.jobEmploymentStatus, jobEmploymentStatusOptions).value;
                console.log("itemm=>", item);
                let role = item.role;
                let planAmountDetails = item.planTypesDetails;
                return (
                  <li key={item.id + index}>
                    <Card className="job-list-container">
                      {/* onClick={() => navigateToJobDetails(item, crewRole, jobEmploymentStatus)} */}
                      <CardBody>
                        <Delete
                          className="job-delete-icon"
                          onClick={(e) => handleDeleteSubscription(item, e)}
                          style={{ color: "#373a48" }}
                        />
                        <FaEdit
                          className="job-edit-icon"
                          onClick={(e) => handleEditSubscription(item, e)}
                          style={{ fontSize: 23, color: "#373a48" }}
                        />

                        <div
                          style={{
                            columnCount: 2,
                            flexDirection: "row",
                            marginTop: 15,
                            marginBottom: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="job-parameter-container"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              width: "50.5%",
                            }}
                          >
                            <pre className="job-parameter-value">
                              {i18next.t("Plan Name")}:{" "}
                            </pre>
                            <p className="job-parameter-value-text">
                              {item.planName}
                            </p>
                          </div>

                          <div
                            className="job-parameter-container"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <pre className="job-parameter-value">
                              {i18next.t("Plan available for")}:{" "}
                            </pre>
                            {role.map((item, index) => {
                              console.log("role=>", item);
                              if (item?.role === "PILOT") {
                                return "Pilot,   ";
                              }
                              if (item?.role === "FLIGHT_ATTENDANT") {
                                return "Flight Attendant,  ";
                              }
                              if (item?.role === "GENERAL") {
                                return "General ";
                              }
                              return (
                                <>
                                  <div className="wrap-in-row">
                                    <p className="job-parameter-value-text-role">
                                      {" "}
                                      {(index ? ", " : "") + item.role}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>

                        <div style={{ flexDirection: "row", display: "flex" }}>
                          {planAmountDetails.map((item, index) => {
                            console.log("planAmount=>", item);
                            return (
                              <div
                                style={{
                                  columnCount: "auto",
                                  flexDirection: "row",
                                  marginTop: 5,
                                  marginBottom: 15,
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {item.planDuration == 30 &&
                                item.planAmount > 0 ? (
                                  <div
                                    className="job-parameter-container"
                                    style={{
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: 342,
                                    }}
                                  >
                                    <pre className="job-parameter-value">
                                      {i18next.t("Plan Type")}:{" "}
                                    </pre>
                                    <p className="job-parameter-value-text">
                                      ${item.planAmount} /monthly{" "}
                                    </p>
                                  </div>
                                ) : item.planDuration === 180 &&
                                  item.planAmount > 0 ? (
                                  <div
                                    className="job-parameter-container"
                                    style={{
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: 342,
                                    }}
                                  >
                                    <pre className="job-parameter-value">
                                      {i18next.t("Plan Type")}:{" "}
                                    </pre>
                                    <p className="job-parameter-value-text">
                                      ${item.planAmount} /6 months{" "}
                                    </p>
                                  </div>
                                ) : item.planDuration == 365 &&
                                  item.planAmount > 0 ? (
                                  <div
                                    className="job-parameter-container"
                                    style={{
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: 342,
                                    }}
                                  >
                                    <pre className="job-parameter-value">
                                      {i18next.t("Plan Type")}:{" "}
                                    </pre>
                                    <p className="job-parameter-value-text">
                                      ${item.planAmount} /year{" "}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>

                        <div className="job-parameter-container">
                          <pre className="job-parameter-value">
                            {i18next.t("Benefits")}:{" "}
                          </pre>
                        </div>

                        <div
                          style={{
                            columnCount: 3,
                            flexDirection: "row",
                            marginTop: 15,
                            marginBottom: 15,
                          }}
                        >
                          {item.numberOfAircrafts > 0 ? (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#000000",
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginRight: 8,
                                }}
                              ></div>
                              <p className="job-parameter-value-text">
                                No of Aircraft Added:
                              </p>
                              <p className="job-parameter-value-text-amount">
                                {item.numberOfAircrafts}
                              </p>
                            </div>
                          ) : null}

                          {item.numberOfPilot > 0 && (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#000000",
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginRight: 8,
                                }}
                              ></div>
                              <p className="job-parameter-value-text">
                                No of Pilots:
                              </p>
                              <p className="job-parameter-value-text-amount">
                                {item.numberOfPilot}
                              </p>
                            </div>
                          )}

                          {item.numberOfAdminAndManagers > 0 && (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#000000",
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginRight: 8,
                                }}
                              ></div>
                              <p className="job-parameter-value-text">
                                No of General Users:
                              </p>
                              <p className="job-parameter-value-text-amount">
                                {item.numberOfAdminAndManagers}
                              </p>
                            </div>
                          )}
                          {item.numberOfAttendants > 0 && (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#000000",
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginRight: 8,
                                }}
                              ></div>
                              <p className="job-parameter-value-text">
                                No of Attendants:
                              </p>
                              <p className="job-parameter-value-text-amount">
                                {item.numberOfAttendants}
                              </p>
                            </div>
                          )}
                          {item.planType === "Job Post" && (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#000000",
                                  height: 8,
                                  width: 8,
                                  borderRadius: 100,
                                  marginRight: 8,
                                }}
                              ></div>
                              <p className="job-parameter-value-text">
                                Unlimited Job Postings
                              </p>
                            </div>
                          )}
                          {/* </div>
                                            <div className="equal-division" style={{ marginTop: -10 }}> */}

                          {item.numberOfPilot > 0 && (
                            <div
                              className="job-parameter-container"
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <p className="job-parameter-value-text"></p>
                              <p className="job-parameter-value-text-amount"></p>
                            </div>
                          )}
                        </div>

                        <div className="job-parameter-container">
                          <pre className="job-parameter-value">
                            {i18next.t("Plan Details")}:{" "}
                          </pre>
                          <div
                            style={{
                              justifyContent: "space-between",
                              display: "flex",
                              marginTop: 10,
                            }}
                          >
                            <div className="seprate">
                              <p className="job-parameter-value-text">
                                {item.planDetailsText}
                              </p>
                            </div>
                            <div className="bottom-plan-toggel">
                              <p className="activate-deactivate-text">
                                Activate/Deactivate Plan
                              </p>
                              {/* <ToggleSwitch/> */}
                              {/* {item.isActivate ? */}

                              {/* {item.isActivate == false && toggleOn == false ? */}
                              {allSubscriptionList[index].isActivate == true ? (
                                <div
                                  className="wrap-in-row"
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                  onClick={(e) =>
                                    handleActivateDeactivate(item, e, index)
                                  }
                                >
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      height: 18,
                                      width: 20,
                                      borderRadius: 20,
                                      paddingLeft: 15,
                                      position: "absolute",
                                    }}
                                  />
                                  <div
                                    style={{
                                      backgroundColor: "#63a94e",
                                      height: 20,
                                      width: 45,
                                      borderRadius: 20,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="wrap-in-row"
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                  onClick={(e) =>
                                    handleActivateDeactivate(item, e, index)
                                  }
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#d4d4d4",
                                      height: 20,
                                      width: 45,
                                      borderRadius: 20,
                                    }}
                                  />
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      height: 18,
                                      width: 20,
                                      position: "absolute",
                                      marginLeft: 23,
                                      borderRadius: 20,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </li>
                );
              })
            : null}
        </InfiniteScroll>
      </List>
    );
  };

  const toggle = () => {
    if (modal) {
      // inputFileRef.current.value = '';
    }
    setModal(!modal);
    clearStateMethod();
  };

  const editToggle = () => {
    if (editSubscriptionModal) {
    }
    setEditSubscriptionModal(!editSubscriptionModal);
    clearStateMethod();
  };
  const handlePlanNameInput = (value) => {
    setPlanName(value);
    if (plan_name != null || plan_name != "") {
      setPlanNameValid("");
    }

    console.log("Value-------------------------------------------->", value);
  };
  const handleMonthlyPlanAmmountInput = (value) => {
    setMonthlyPlanAmmount(value);
    if (monthlyPlanAmmount != null || monthlyPlanAmmount != "") {
      setMonthlyPlanValid("");
    }
  };
  const handleSixMonthlyPlanAmmountInput = (value) => {
    setSixMonthlyPlanAmmount(value);
    if (sixMonthlyPlanAmmount != null || sixMonthlyPlanAmmount != "") {
      setSixMonthlyPlanValid("");
    }
  };
  const handleYearlyPlanAmmountInput = (value) => {
    setYearlyPlanAmmount(value);
    if (yearlyPlanAmmount != null || yearlyPlanAmmount != "") {
      setYearlyPlanValid("");
    }
  };
  const handleNoOfAircraftInput = (value) => {
    setNoOfAirCraft(value);
    if (noOfAircraft != null || noOfAircraft != "") {
      setNoOfAircraftValid("");
    }
  };
  const handleNoOfPassengerInput = (value) => {
    setNoOfPassenget(value);
    if (noOfPassenger != null || noOfPassenger != "") {
      setNoOfPassengerValid("");
    }
  };
  const handleNoOfPilotInput = (value) => {
    setNoOfPilots(value);
    if (noOfPilot != null || noOfPilot != "") {
      setNoOfPilotValid("");
    }
  };
  const handleNoOfAttendanceInput = (value) => {
    setNoOfAttendance(value);
    if (noOfAttendance != null || noOfAttendance != "") {
      setNoOfAttendentValid("");
    }
  };
  const handlePlanDetailInput = (value) => {
    setPlanDetails(value);
    if (planDetails != null || planDetails != "") {
      setPlanDetailsValid("");
    }
  };
  const handleIsPilotCheckBoxChecked = () => {
    setIsPilotCheckBoxChecked(!isPilotCheckBoxChecked);
    if (isPilotCheckBoxChecked == true) {
      setRoleValid("");
    }
  };
  const handleIsFlightAttendentCheckBoxChecked = () => {
    setIsFlightAttendentCheckBoxChecked(!isFlightAttendentCheckBoxChecked);
    if (isFlightAttendentCheckBoxChecked == true) {
      setRoleValid("");
    }
  };
  const handleisGenralCheckBoxChecked = (e) => {
    setIsGenralCheckBoxChecked(e.target.checked);
    if (isGenralCheckBoxChecked == false) {
      setIsApplyJobCheckBoxChecked(false);
    }
    if (isGenralCheckBoxChecked == true) {
      setRoleValid("");
    }
    // console.log("isGenralCheckBoxChecked-------------------419-------419----419--------->", e.target.checked);
  };

  const toggleState = () => {
    setOpenDropdown(!openDropdown);
    setShowDropdown(!showDropdown);

    // set
  };

  const handleIsApplyJobCheckBoxChecked = (e) => {
    console.log(
      "isApplyJobCheckBoxChecked----------111111111111111--->>>>>>>>>",
      isApplyJobCheckBoxChecked
    );
    if (e.target.checked === true) {
      console.log("Inside if--------------------------------------------");
      console.log(
        "isApplyJobCheckBoxChecked----------333333--->>>>>>>>>",
        e.target.checked
      );
      setIsApplyJobCheckBoxChecked(!isApplyJobCheckBoxChecked);
      console.log(
        "isApplyJobCheckBoxChecked----------11122222222222222222222--->>>>>>>>>",
        isApplyJobCheckBoxChecked
      );
    } else {
      console.log(
        "Else-------------------------------------------------------"
      );
      setIsApplyJobCheckBoxChecked(false);
    }
    // console.log("isApplyJobCheckBoxChecked----------222222222222222--->>>>>>>>>", isApplyJobCheckBoxChecked);
  };

  const planNameMethod = () => {
    return (
      <>
        <TextField
          // style ={{color: #1a1a1a}}
          label={i18next.t("PLANNAME_LABEL")}
          type="text"
          name="name"
          onChange={(e) => {
            handlePlanNameInput(e.target.value);
          }}
          value={plan_name}
        />
        {dropdownKeyword == "Flight Department" ? (
          <span
            style={{
              fontSize: 10,
              bottom: 573,
              position: "absolute",
              color: "red",
              marginLeft: 10,
            }}
          >
            {planNameValid}
          </span>
        ) : (
          <span
            style={{
              fontSize: 10,
              bottom: 435,
              position: "absolute",
              color: "red",
              marginLeft: 10,
            }}
          >
            {planNameValid}
          </span>
        )}
      </>
    );
  };

  const threeCheckBoxesMethod = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 25,
        }}
      >
        <div style={{ marginRight: 35 }}>
          <input
            style={{ marginRight: 12 }}
            type="checkbox"
            // id="topping"
            // name="topping"
            value={isPilotCheckBoxChecked}
            checked={isPilotCheckBoxChecked}
            onChange={handleIsPilotCheckBoxChecked}
          />
          Pilot
        </div>
        <div style={{ marginRight: 32 }}>
          <input
            style={{ marginRight: 12 }}
            type="checkbox"
            // id="topping"
            // name="topping"
            value={isFlightAttendentCheckBoxChecked}
            checked={isFlightAttendentCheckBoxChecked}
            onChange={handleIsFlightAttendentCheckBoxChecked}
          />
          Flight Attendant
        </div>
        <div>
          <input
            style={{ marginRight: 12 }}
            type="checkbox"
            // id="topping"
            // name="topping"
            value={isGenralCheckBoxChecked}
            checked={isGenralCheckBoxChecked}
            onChange={handleisGenralCheckBoxChecked}
            disabled={dropdownKeyword == "Job Post" ? true : false}
          />
          General
        </div>
      </div>
    );
  };
  const allInputFieldMethods = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{}}>
            <TextField
              disabled
              style={{ width: 225, height: 40 }}
              label={i18next.t("PLAN_DURATION_LABEL")}
              type="text"
              name="plan_name"
              defaultValue={"Monthly"}
            />
          </div>
          <div>
            <TextField
              style={{ width: 225, height: 40 }}
              disabled={editSubscriptionModal ? true : false}
              label={i18next.t("PLAN_AMMOUNT")}
              type="number"
              min="0"
              name="planAmmount"
              step="0.1"
              onChange={(e) => {
                handleMonthlyPlanAmmountInput(e.target.value);
              }}
              inputProps={{
                inputMode: "decimal",
              }}
              value={monthlyPlanAmmount}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            {dropdownKeyword == "Flight Department" ? (
              <span
                style={{
                  fontSize: 10,
                  bottom: 455,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {monthlyPlanValid}
              </span>
            ) : (
              <span
                style={{
                  fontSize: 10,
                  bottom: 315,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {monthlyPlanValid}
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{}}>
            <TextField
              style={{ width: 225, height: 40 }}
              disabled
              label={i18next.t("PLAN_DURATION_LABEL")}
              type="text"
              name="plan_name"
              // defaultValue={"Def"}
              defaultValue={"6 Months"}
            />
          </div>
          <div>
            <TextField
              style={{ width: 225, height: 40 }}
              label={i18next.t("PLAN_AMMOUNT")}
              type="number"
              disabled={editSubscriptionModal ? true : false}
              min="0"
              name="sixMonthlyPlanAmmount"
              step="0.1"
              onChange={(e) => {
                handleSixMonthlyPlanAmmountInput(e.target.value);
              }}
              value={sixMonthlyPlanAmmount}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            {dropdownKeyword == "Flight Department" ? (
              <span
                style={{
                  fontSize: 10,
                  bottom: 385,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {sixMonthlyPlanValid}
              </span>
            ) : (
              <span
                style={{
                  fontSize: 10,
                  bottom: 246,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {sixMonthlyPlanValid}
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{}}>
            <TextField
              style={{ width: 225, height: 40 }}
              disabled
              label={i18next.t("PLAN_DURATION_LABEL")}
              type="text"
              name="plan_name"
              defaultValue={"Yearly"}
            />
          </div>
          <div>
            <TextField
              style={{ width: 225, height: 40 }}
              label={i18next.t("PLAN_AMMOUNT")}
              type="number"
              disabled={editSubscriptionModal ? true : false}
              name="yearlyPlanAmmount"
              min="0"
              step="0.1"
              onChange={(e) => {
                handleYearlyPlanAmmountInput(e.target.value);
              }}
              value={yearlyPlanAmmount}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            {dropdownKeyword == "Flight Department" ? (
              <span
                style={{
                  fontSize: 10,
                  bottom: 316,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {yearlyPlanValid}
              </span>
            ) : (
              <span
                style={{
                  fontSize: 10,
                  bottom: 176,
                  position: "absolute",
                  marginLeft: 10,
                  color: "red",
                }}
              >
                {yearlyPlanValid}
              </span>
            )}
          </div>
        </div>

        {dropdownKeyword == "Flight Department" && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{}}>
                <TextField
                  style={{ width: 225, height: 40 }}
                  label={i18next.t("NO_OF_AIRCRAFT")}
                  type="number"
                  name="noOfAircraft"
                  min="1"
                  onChange={(e) => {
                    handleNoOfAircraftInput(e.target.value);
                  }}
                  value={noOfAircraft}
                  onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()
                  }
                />
                {dropdownKeyword == "Flight Department" ? (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 247,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfAircraftValid}
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 280,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfAircraftValid}
                  </span>
                )}
              </div>
              <div>
                <TextField
                  style={{ width: 225, height: 40 }}
                  label={i18next.t("No of General Users")}
                  type="number"
                  name="noOfPassenger"
                  min="1"
                  onChange={(e) => {
                    handleNoOfPassengerInput(e.target.value);
                  }}
                  value={noOfPassenger}
                  onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()
                  }
                />
                {dropdownKeyword == "Flight Department" ? (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 247,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfPassengerValid}
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 280,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfPassengerValid}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={
                  {
                    // marginRight:16
                  }
                }
              >
                <TextField
                  style={{ width: 225, height: 40 }}
                  label={i18next.t("NO_OF_PILOT")}
                  type="number"
                  name="noOfPilot"
                  min="1"
                  onChange={(e) => {
                    handleNoOfPilotInput(e.target.value);
                  }}
                  value={noOfPilot}
                  onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()
                  }
                />
                {dropdownKeyword == "Flight Department" ? (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 175,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfPilotValid}
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 210,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfPilotValid}
                  </span>
                )}
              </div>
              <div>
                <TextField
                  style={{ width: 225, height: 40 }}
                  label={i18next.t("NO_OF_ATTENDANTS")}
                  type="number"
                  name="noOfAttendance"
                  min="1"
                  onChange={(e) => {
                    handleNoOfAttendanceInput(e.target.value);
                  }}
                  value={noOfAttendance}
                  onKeyDown={(e) =>
                    exceptThisSymbols2.includes(e.key) && e.preventDefault()
                  }
                />
                {dropdownKeyword == "Flight Department" ? (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 175,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfAttendentValid}
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 10,
                      bottom: 210,
                      position: "absolute",
                      marginLeft: 10,
                      color: "red",
                    }}
                  >
                    {noOfAttendentValid}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const applyJobCheckBoxMethod = () => {
    return (
      <div style={{ marginRight: 32, marginBottom: 12 }}>
        <input
          style={{ marginRight: 12 }}
          type="checkbox"
          id="topping"
          name="topping"
          value={isApplyJobCheckBoxChecked}
          checked={isApplyJobCheckBoxChecked}
          onChange={(e) => handleIsApplyJobCheckBoxChecked(e)}
        />
        Apply for unlimited job posts
      </div>
    );
  };
  const planDetailInputFieldMethod = () => {
    return (
      <>
        <div className="input-container">
          <div className="form-group has-float-label">
            <textarea
              style={{
                borderWidth: 1,
                borderColor: "#0f0643",
                fontSize: 15,
                height: 80,
              }}
              className={`form-control shadow-none}`}
              placeholder=" "
              type="textarea"
              name="planDetails"
              onChange={(e) => {
                handlePlanDetailInput(e.target.value);
              }}
              value={planDetails}
            />
            <label>Plan Details</label>
          </div>
        </div>

        {isGenralCheckBoxChecked == true ? (
          <span
            style={{
              fontSize: 10,
              bottom: 65,
              position: "absolute",
              marginLeft: 10,
              color: "red",
            }}
          >
            {planDetailsValid}
          </span>
        ) : (
          <span
            style={{
              fontSize: 10,
              bottom: 65,
              position: "absolute",
              marginLeft: 10,
              color: "red",
            }}
          >
            {planDetailsValid}
          </span>
        )}
      </>
    );
  };

  const handleDropdown = (e) => {
    setDropdownKeyword("Job Post");
    setIsGenralCheckBoxChecked(false);
    setNoOfPilots("");
    setNoOfAirCraft("");
    setNoOfAttendance("");
    setNoOfPassenget("");
  };

  const planTypeDropdownMethod = () => {
    return (
      <>
        <div className="typeDropdown">
          <div>
            {showDropdown != true && (
              <>
                <div
                  className="inputBox"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <select style={{ height: 42 }} className="dropdown1">
                    <option onClick={() => toggleState}>
                      {dropdownKeyword}
                    </option>
                  </select>
                  {dropdownKeyword != "Plan Type" ? (
                    <span>Plan Type</span>
                  ) : null}
                  {dropdownKeyword == "Job Post" && (
                    <p>(Apply for unlimited job post)</p>
                  )}
                </div>
              </>
            )}
          </div>
          {/* {
                        showDropdown == true &&
                        // <div onClick={() => setShowDropdown(!showDropdown)} >
                            <Dropdown  id="float-lable" className="dropdown_btn" isOpen={openDropdown} toggle={toggleState}>
                                <DropdownMenu className="dropdown_btn">
                                    <DropdownItem onClick={(e) => setDropdownKeyword(e.currentTarget.textContent)}
                                        className="dropdown_btn_item">Flight Department</DropdownItem>
                                    <DropdownItem onClick={(e) => { setDropdownKeyword("Job Post"); setIsGenralCheckBoxChecked(false) }}
                                        className="dropdown_btn_item">Job Post <span style={{ fontSize: 13, marginLeft: 10 }}>(Apply for unlimited job posts)</span></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        // </div>
                    } */}
          {showDropdown == true && (
            <Dropdown
              style={{ position: "relative" }}
              id="float-lable"
              className="dropdown_btn"
              isOpen={true}
              toggle={toggleState}
            >
              <DropdownToggle className="caret" color="black" caret>
                {dropdownKeyword}{" "}
                {dropdownKeyword == "Job Post" ? (
                  <span style={{ fontSize: 12, marginLeft: 10 }}>
                    (Apply for unlimited job posts
                  </span>
                ) : null}
              </DropdownToggle>
              <DropdownMenu className="dropdown_btn">
                <DropdownItem
                  onClick={(e) =>
                    setDropdownKeyword(e.currentTarget.textContent)
                  }
                  className="dropdown_btn_item"
                >
                  Flight Department
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => handleDropdown()}
                  className="dropdown_btn_item"
                >
                  Job Post{" "}
                  <span style={{ fontSize: 13, marginLeft: 10 }}>
                    (Apply for unlimited job posts)
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {dropdownKeyword != "Plan Type" ? null : (
            <span
              style={{
                fontSize: 10,
                bottom: 505,
                position: "absolute",
                marginLeft: 10,
                color: "red",
              }}
            >
              {planTypeDropdownValid}
            </span>
          )}
        </div>
      </>
    );
  };

  const clearStateMethod = () => {
    setIsPilotCheckBoxChecked(false);
    setIsApplyJobCheckBoxChecked(false);
    setIsFlightAttendentCheckBoxChecked(false);
    setIsGenralCheckBoxChecked(false);
    setPlanName("");
    setPlanDetails("");
    setIsApplyJobCheckBoxChecked("");
    setNoOfAirCraft("");
    setNoOfAttendance("");
    setNoOfPassenget("");
    setNoOfPilots("");
    setIsActivate("");
    setMonthlyPlanAmmount("");
    setSixMonthlyPlanAmmount("");
    setYearlyPlanAmmount("");
    setPlanNameValid("");
    setPlanDetailsValid("");
    setNoOfAircraftValid("");
    setNoOfAttendentValid("");
    setNoOfPassengerValid("");
    setNoOfPilotValid("");
    setMonthlyPlanValid("");
    setYearlyPlanValid("");
    setSixMonthlyPlanValid("");
    setDropdownKeyword("Plan Type");
    setPlanTypeDropdownvalid("");
  };
  const addButtonClickedMethod = () => {
    console.log("add");
    return (
      <div className="submit-btn-wrapper">
        <button
          type="button"
          onClick={() => addButtonClicked()}
          className={`solid-btn ripple-light-ms-update`}
          // className="Bottom-Add-Btn"
        >
          {i18next.t("ADD_LABEL")}
        </button>
      </div>
    );
  };

  const editSubscriptionButtonClickedMethod = () => {
    return (
      <div className="submit-btn-wrapper">
        <button
          type="button"
          onClick={() => editSubscriptionAddButtonClicked()}
          className={`solid-btn ripple-light-ms-update`}
          // className="Bottom-Add-Btn"
        >
          {i18next.t("Save")}
        </button>
      </div>
    );
  };

  const addButtonClicked = () => {
    // console.log("plan_name----------------------------------------->", plan_name)
    // console.log("monthlyPlanAmmount----------------------->>>", monthlyPlanAmmount);
    // console.log("sixMonthlyPlanAmmount----------------------->>>", sixMonthlyPlanAmmount);
    // console.log("yearlyPlanAmmount----------------------->>>", yearlyPlanAmmount);
    // console.log("noOfAircraft----------------------->>>", noOfAircraft);
    // console.log("noOfPassenger----------------------->>>", noOfPassenger);
    // console.log("noOfPilot----------------------->>>", noOfPilot);
    // console.log("noOfAttendance----------------------->>>", noOfAttendance);
    // console.log("planDetails----------------------->>>", planDetails);
    // console.log("noOfAttendance----------------------->>>", noOfAttendance);
    // console.log("isPilotCheckBoxChecked----------------------->>>", isPilotCheckBoxChecked);
    // console.log("isFlightAttendentCheckBoxChecked----------------------->>>", isFlightAttendentCheckBoxChecked);
    // console.log("isGenralCheckBoxChecked-------------->>>", isGenralCheckBoxChecked)
    // console.log("isApplyJobCheckBoxChecked------------>>>", isApplyJobCheckBoxChecked);
    // let payload;
    if (dropdownKeyword == "Plan Type") {
      setPlanTypeDropdownvalid("Please select plan type");
      return;
    }

    if (plan_name == "" || plan_name == null) {
      setPlanNameValid("Please enter plan name");
      return;
    }
    if (
      isPilotCheckBoxChecked == false &&
      isFlightAttendentCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      //    alert("Please add role")
      showToast("Please add role", "error");
      return;
    }
    if (monthlyPlanAmmount == "") {
      setMonthlyPlanValid("Please enter the cost here");
      return;
    }
    if (sixMonthlyPlanAmmount == "" || sixMonthlyPlanValid == null) {
      setSixMonthlyPlanValid("Please enter the cost here");
      return;
    }
    if (yearlyPlanAmmount == "" || yearlyPlanAmmount == null) {
      setYearlyPlanValid("Please enter the cost here");
      return;
    }

    if (dropdownKeyword == "Flight Department") {
      if (noOfAircraft == "" || noOfAircraft == null) {
        setNoOfAircraftValid("Please enter the aircraft count");
        return;
      }

      if (noOfAircraft < 1) {
        setNoOfAircraftValid("Minimum No of aircraft count is 1");
        return;
      }
      if (noOfPassenger == "" || noOfPassenger == null) {
        setNoOfPassengerValid("Please enter the General user count");
        return;
      }

      if (noOfPassenger < 1) {
        setNoOfPassengerValid("Minimum General user count is 1");
        return;
      }
      if (noOfPilot == "" || noOfPilot == null) {
        setNoOfPilotValid("Please enter the pilot count");
        return;
      }

      if (noOfPilot < 1) {
        setNoOfPilotValid("Minimum pilot count is 1");
        return;
      }
      if (noOfAttendance == "" || noOfAttendance == null) {
        setNoOfAttendentValid("Please enter the Flight attendant count");
        return;
      }

      if (noOfAttendance < 1) {
        setNoOfAttendentValid("Minimum flight attendant count is 1");
        return;
      }
    }

    if (planDetails == "" || planDetails == null) {
      setPlanDetailsValid("Please enter the Plan Description");
      return;
    }

    if (
      monthlyPlanAmmount == 0 &&
      sixMonthlyPlanAmmount == 0 &&
      yearlyPlanAmmount == 0
    ) {
      showToast(
        "Please add at least one cost to create a subscription",
        "error"
      );
      return;
    }

    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      console.log("pilot");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
        ],
      };

      // =======================create subscription from modal==============================
      console.log("========payload general checked======>", payload);
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          console.log("erorr==>", error);
          setIsLoading(false);
        });
    }
    if (
      isFlightAttendentCheckBoxChecked == true &&
      isPilotCheckBoxChecked == false &&
      isGenralCheckBoxChecked == false
    ) {
      console.log("flight");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "FLIGHT_ATTENDANT",
          },
        ],
      };

      console.log("======flight attendeant checked=========>0000", payload);
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
        });
    }
    if (
      isGenralCheckBoxChecked == true &&
      isPilotCheckBoxChecked == false &&
      isFlightAttendentCheckBoxChecked == false
    ) {
      console.log("general");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: true,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planName: plan_name,
        planDetailsText: planDetails,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],

        role: [
          {
            role: "GENERAL",
          },
        ],
      };
      console.log("==========genral checked==========>0000", payload);
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
        });
    }
    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == true &&
      isGenralCheckBoxChecked == false
    ) {
      console.log("pilot and flight");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planName: plan_name,
        planDetailsText: planDetails,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "FLIGHT_ATTENDANT",
          },
        ],
      };
      console.log(
        "====pilot and flight attendant checked=========>0000",
        payload
      );

      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
        });
    }
    if (
      isPilotCheckBoxChecked == true &&
      isGenralCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == false
    ) {
      console.log("pilot and general");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planName: plan_name,
        planDetailsText: planDetails,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "GENERAL",
          },
        ],
      };

      console.log("=======pilot and general checked==========>0000", payload);
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
          setIsLoading(false);
        });
    }
    if (
      isGenralCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == true &&
      isPilotCheckBoxChecked == false
    ) {
      console.log("flight and general");
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "FLIGHT_ATTENDANT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log(
        "=========filght attendeant and genaral checked=========>0000",
        payload
      );
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
          setIsLoading(false);
        });
    }

    if (
      isPilotCheckBoxChecked == true &&
      isFlightAttendentCheckBoxChecked == true &&
      isGenralCheckBoxChecked == true
    ) {
      setIsLoading(true);
      let payload = {
        planType: dropdownKeyword,
        isActivate: false,
        isEligibleForUnlimitedPost: isApplyJobCheckBoxChecked,
        numberOfAircrafts: noOfAircraft,
        numberOfAttendants: noOfAttendance,
        numberOfAdminAndManagers: noOfPassenger,
        numberOfPilot: noOfPilot,
        planDetailsText: planDetails,
        planName: plan_name,
        planTypesDetails: [
          {
            planAmount: monthlyPlanAmmount,
            planDuration: "MONTHLY",
          },
          {
            planAmount: sixMonthlyPlanAmmount,
            planDuration: "SIX_MONTHLY",
          },
          {
            planAmount: yearlyPlanAmmount,
            planDuration: "YEARLY",
          },
        ],
        role: [
          {
            role: "PILOT",
          },
          {
            role: "FLIGHT_ATTENDANT",
          },
          {
            role: "GENERAL",
          },
        ],
      };
      console.log(
        "=========filght attendeant and genaral checked=========>0000",
        payload
      );
      createSubscriptionAPI(payload)
        .then((response) => {
          console.log("oooooooo==>", response);
          if (response.code == 200) {
            alert(response.message);
            setIsLoading(false);
            setModal(false);
            clearStateMethod();
            getAllSubscription();
          } else {
          }
        })
        .catch((error) => {
          // console.log("erorr==>", error)
          setIsLoading(false);
        });
    }
  };

  // const openEditSubscriptionPopUp = () => {
  //     console.log("isGenralCheckBoxChecked----------------------------------------->", isGenralCheckBoxChecked);
  //     return

  const openEditSubscriptionPopUp = () => {
    console.log(
      "isGenralCheckBoxChecked----------------------------------------->",
      isGenralCheckBoxChecked
    );
    return (
      <Modal
        isOpen={editSubscriptionModal}
        toggle={editToggle}
        className={"entity-update-modal"}
        centered
      >
        <ModalBody>
          <>
            <div className="heading-wrapper">
              <div className="heading-text">
                {i18next.t("Edit Subscription")}
              </div>
              <div className="close-div">
                <img
                  src={ModalCrossBlack}
                  onClick={editToggle}
                  alt="close-icon"
                />
              </div>
            </div>
            <div className="details-wrapper">
              {/* <div className="entity-file-details"> */}
              {/* <div className="data-file-text-wrapper">{i18next.t('ENTITY_DATA_FILE_TEXT')}</div> */}
              <div className="data-file-chip-wrapper">
                <div className="data-chip">
                  {/* <div className="file-name" > */}
                  <AppForm>
                    {planTypeDropdownMethod()}
                    {planNameMethod()}
                    {threeCheckBoxesMethod()}
                    {allInputFieldMethods()}
                    {/* {!isGenralCheckBoxChecked && applyJobCheckBoxMethod()} */}
                    {planDetailInputFieldMethod()}
                    {editSubscriptionButtonClickedMethod()}
                  </AppForm>
                </div>
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
    );
  };

  const openAddNewSubscriptionPopUp = () => {
    console.log(
      "isGenralCheckBoxChecked----------------------------------------->",
      isGenralCheckBoxChecked
    );
    return (
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={"entity-update-modal"}
        centered
      >
        <ModalBody>
          <>
            <div className="heading-wrapper">
              <div className="heading-text">
                {i18next.t("ADD_NEW_SUBSCRIPTION")}
              </div>
              <div className="close-div">
                <img src={ModalCrossBlack} onClick={toggle} alt="close-icon" />
              </div>
            </div>
            <div className="details-wrapper">
              {/* <div className="entity-file-details"> */}
              {/* <div className="data-file-text-wrapper">{i18next.t('ENTITY_DATA_FILE_TEXT')}</div> */}
              <div className="data-file-chip-wrapper">
                <div className="data-chip">
                  {/* <div className="file-name" > */}
                  <AppForm
                  //  initialValues={initialValues}
                  //  validationSchema={validationSchema}
                  //  onSubmit={handleSubmit}
                  //  onCheckValid={onCheckValid}
                  >
                    {" "}
                    {planTypeDropdownMethod()}
                    {planNameMethod()}
                    {threeCheckBoxesMethod()}
                    {allInputFieldMethods()}
                    {/* {!isGenralCheckBoxChecked && applyJobCheckBoxMethod()} */}
                    {planDetailInputFieldMethod()}
                    {addButtonClickedMethod()}
                  </AppForm>

                  {/* </div> */}
                  {/* <div className="chip-cross">
                                            <img src={ChipCrossIcon} alt='cross' onClick={toggle} />
                                        </div> */}
                </div>
              </div>
              {/* </div> */}
              {/* <div className="submit-btn-wrapper">
                                <button
                                    type="button"
                                    // onClick={() => submitUpdatedData()}
                                    className={`solid-btn ripple-light-ms-update`}
                                // className="Bottom-Add-Btn"
                                >
                                    {i18next.t('ADD_LABEL')}
                                </button>
                            </div> */}
            </div>
          </>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className="page-container hiring-board-container">
      {showLoader(isLoading)}
      {openAddNewSubscriptionPopUp()}
      {openEditSubscriptionPopUp()}
      <div className="sub-header-wrapper">
        <div className="title-wrapper">
          <span>{i18next.t("SUBSCRIPTIONS_LABEL")}</span>
        </div>

        <div className="action-wrapper">
          {/* <div className="dropdown ">
                        <span className="dropdown-label">{i18next.t("ROLE_LABEL")}</span>
                        <button
                            className="btn  dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div
                                className="selected-dd-text"
                                style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                title={params.selectedRole?.value}>{params.selectedRole?.value}</div>
                            <img
                                className="dropDownArrow"
                                alt="dropdown"
                                src={dropDownArrow}
                            />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {crewList.map((pos) => (
                                <li
                                    key={pos.id}
                                    onClick={() => handleFilterClick(pos, "role")}
                                >
                                    <button className="dropdown-item" type="button">
                                        {pos?.value}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div> */}

          {/* <div className="dropdown ">
                        <span className="dropdown-label">{i18next.t("TYPE")}</span>
                        <button
                            className="btn  dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {params.selectedFilter?.value}
                            <img
                                className="dropDownArrow"
                                alt="dropdown"
                                src={dropDownArrow}
                            />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {jobEmploymentStatusOptions.map((filter) => (
                                <li
                                    key={filter.id}
                                    onClick={() => handleFilterClick(filter, "filter")}
                                >
                                    <button className="dropdown-item" type="button">
                                        {filter.value}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div> */}
          {/* <span className="search-container search-form">
                        <span className="ipad-fixes">{' '}</span>
                        <img className="search-icon search-icon-user" alt="search" src={searchIcon} />
                        <input
                            type="text"
                            name="search"
                            id="search"
                            value={preSearchText}
                            onChange={(event) => handleSearchChange(event.target.value)}
                            onKeyPress={(e) => submitSearch(e)}
                            placeholder="Search"
                            className="form-control search-background"
                        />
                        {preSearchText && preSearchText.length > 0 ? (
                            <img
                                className="clear-search clear-search-user"
                                onClick={() => handleClearSearch()}
                                alt="cross"
                                src={clearSearch}
                            />
                        ) : (
                            ""
                        )}
                    </span> */}
          <div>
            <button
              style={{
                backgroundColor: "#0f0643",
                borderRadius: 12,
                paddingInlineEnd: 5,
                fontSize: 13,
                fontWeight: "400",
                color: "white",
                width: 82,
              }}
              // onClick={()=>{ alert('alert'); }}
              onClick={() => toggle()}
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      <div className="common-wrapper" id="containerScroll">
        {renderJobList()}
        {renderEmptyView()}
      </div>
      {/* ************ Alerts ************ */}
      <ConfirmAlert
        confirmMsg={i18next.t(
          "Are you sure you want to delete this Subscription?"
        )}
        positiveBTN={i18next.t("Yes")}
        negativeBTN={i18next.t("No")}
        isOpen={deleteJob}
        customClass={"danger-btn"}
        positiveCallback={() => {
          showDelete(false);
          handleDelete();
        }}
        negativeCallback={() => showDelete(false)}
      />

      {/* ************ LOADER ************ */}
      {showLoader(isLoading)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  jobData: state.hiringBoard.selectedJobData,
  paramData: state.hiringBoard.paramData,
});

export default connect(mapStateToProps, {
  saveSelectedJobData,
  saveHiringBoardParamData,
})(SubscriptionDetails);
